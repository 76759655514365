import React from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { GovernanceTabs } from '@Shared.Angular/flowingly.services/flowingly.constants';
import { SharedAngular } from '@Client/@types/sharedAngular';

interface Props {
  appConfig: SharedAngular.APP_CONFIG;
  selectedTab: string;
  onTabChange: (tab: GovernanceTabs) => void;
}

const Tabs: React.FC<Props> = ({ appConfig, selectedTab, onTabChange }) => {
  const tabs = [
    {
      id: GovernanceTabs.MY_APPROVALS,
      label: 'My Approvals',
      visible: appConfig.enableProcessApprovals
    },
    {
      id: GovernanceTabs.SENT_FOR_APPROVAL,
      label: 'Sent for Approval',
      visible: appConfig.enableProcessApprovals
    },
    {
      id: GovernanceTabs.PROCESS_REVIEW_DUE,
      label: 'Process Review Due',
      visible: appConfig.showGovernanceFlowModelsWithReviewDue
    }
  ];

  return (
    <ButtonGroup
      aria-label="Basic button group"
      className="governance-button-group-container"
    >
      {tabs.map(
        (button) =>
          button.visible && (
            <Button
              key={button.id}
              id={button.id}
              className={`governance-button-group ${
                selectedTab === button.id ? 'selected' : ''
              }`}
              onClick={() => onTabChange(button.id)}
            >
              {button.label}
            </Button>
          )
      )}
    </ButtonGroup>
  );
};

export default Tabs;
