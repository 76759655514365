import React from 'react';
import { IProcessOverdueFlowModel } from '@Shared.Angular/@types/core/contracts/queryModel/flowModels/processOverdueFlowModel';

interface Props {
  isMobile: boolean;
  filteredFlowModels: IProcessOverdueFlowModel[];
  onFlowModelClick: (id: string, loadPublishRequestSchema: boolean) => void;
  selectedTab: string;
}

const SentForApprovalList: React.FC<Props> = ({
  isMobile,
  filteredFlowModels,
  selectedTab
}) => {
  return <h3 className="ml-50">Sent for approval</h3>;
};

export default SentForApprovalList;
