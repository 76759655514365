import React, { useEffect, useState } from 'react';
import { useService } from '@Client/runner.hooks/useService';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { Guid } from '@Shared.Angular/@types/guid';
import { GovernanceTabs } from '@Shared.Angular/flowingly.services/flowingly.constants';
import Category from '@Client/runner.directives/common/Category/Category';
import Search from './Search/Search';
import Tabs from './tabs/tabs';
import ProcessReviewDueList from './Lists/ProcessReviewDueList/ProcessReviewDueList';
import MyApprovalsList from './Lists/MyApprovalsList/MyApprovalsList';
import SentForApprovalList from './Lists/SentForApprovalList/SentForApprovalList';

type Props = {
  isMobile: boolean;
  backFromState: string;
  onListItemClicked: (id: Guid, loadPublishRequestSchema: boolean) => void;
};

const Governance = (props: Props) => {
  const { isMobile } = props;
  const governanceLoadProcessReviewDueList =
    'governanceLoadProcessReviewDueList';
  const governanceLoadMyApprovalsList = 'governanceLoadMyApprovalsList';
  const allCategoriesId = 'all-categories';
  const appConfig = useService<SharedAngular.APP_CONFIG>('APP_CONFIG');
  const flowListManager = useService<FlowListManager>('flowListManager');
  const lodashService = useService<Lodash>('lodashService');
  const pubSubService =
    useService<SharedAngular.PubSubService>('pubsubService');
  const appInsightsService =
    useService<SharedAngular.AppInsightsService>('appInsightsService');
  const runnerCategoryNavigationService =
    useService<RunnerCategoryNavigationService>(
      'runnerCategoryNavigationService'
    );

  const [selectedCategory, setSelectedCategory] =
    React.useState(allCategoriesId);
  const [selectedCategoryIndex, setSelectedCategoryIndex] =
    React.useState(null);
  const [showSubCategories] = useState(appConfig?.enableSubCategories || false);
  const [categorisedFlowModels, setCategorisedFlowModels] = useState([]);
  const [flowModels, setFlowModels] = useState([]);
  const [filteredByCategoryFlowModels, setFilteredByCategoryFlowModels] =
    useState([]);
  const [searchedFlowModels, setSearchedFlowModels] = useState([]);
  const [selectedTab, setSelectedTab] = useState(() => {
    if (appConfig.enableProcessApprovals) {
      return GovernanceTabs.MY_APPROVALS;
    }
    if (appConfig.showGovernanceFlowModelsWithReviewDue) {
      return GovernanceTabs.PROCESS_REVIEW_DUE;
    }
    return null;
  });
  const [searchHidden, setSearchHidden] = useState(true);
  const [searchPlaceholder, setSearchPlaceholder] = useState('');
  const [searchItem, setSearchItem] = useState('');

  useEffect(() => {
    const governanceSubscriberId = 'flowingly.runner.governance';
    pubSubService.subscribe(
      'SIGNALR_WORKFLOW_NAME_CHANGED',
      (event, params: string) => updateFlowModelName(params),
      governanceSubscriberId
    );
    pubSubService.subscribe(
      'SIGNALR_SETUP_CATEGORY_DELETED',
      refresh,
      governanceSubscriberId
    );
    pubSubService.subscribe(
      'SIGNALR_SETUP_FLOW_MODEL_DELETED',
      refresh,
      governanceSubscriberId
    );
    pubSubService.subscribe(
      'SIGNALR_WORKFLOW_PUBLISHED',
      refresh,
      governanceSubscriberId
    );
    pubSubService.subscribe(
      'SIGNALR_FLOW_MODEL_PUBLISH_REQUESTED',
      refresh,
      governanceSubscriberId
    );
    pubSubService.subscribe(
      'SIGNALR_WORKFLOW_UNPUBLISHED',
      refresh,
      governanceSubscriberId
    );
    return () => {
      pubSubService.unsubscribeAll(governanceSubscriberId);
    };
  }, []);

  useEffect(() => {
    fetchCategorizedFlowModels(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    getFlowModelsFromCategorisedFlowModels();
  }, [categorisedFlowModels]);

  useEffect(() => {
    filterAction();
  }, [flowModels, selectedCategory, searchItem]);

  const fetchMyApprovalsFlowModels = async () => {
    appInsightsService.startEventTimer(governanceLoadMyApprovalsList);
    const categorisedFlowModels =
      await flowListManager.getCategorizedMyApprovalFlowModels(false);

    if (appConfig.enableSubCategories) {
      setCategorisedFlowModels(
        runnerCategoryNavigationService.getFormattedTreeData(
          categorisedFlowModels
        )
      );
    } else {
      setCategorisedFlowModels(categorisedFlowModels);
    }
    appInsightsService.trackMetricIfTimerExist(governanceLoadMyApprovalsList);
  };

  const fetchSentForApprovalFlowModels = async () => {
    setCategorisedFlowModels([]);
  };

  const fetchProcessReviewFlowModels = async () => {
    appInsightsService.startEventTimer(governanceLoadProcessReviewDueList);
    const categorisedFlowModels =
      await flowListManager.getCategorizedProcessOverdueFlowModels(false);

    if (appConfig.enableSubCategories) {
      setCategorisedFlowModels(
        runnerCategoryNavigationService.getFormattedTreeData(
          categorisedFlowModels
        )
      );
    } else {
      setCategorisedFlowModels(categorisedFlowModels);
    }
    appInsightsService.trackMetricIfTimerExist(
      governanceLoadProcessReviewDueList
    );
  };

  const filterFlowModelsByCategory = (id: string, categoryIndex?: string) => {
    setSelectedCategory(id);
    setSelectedCategoryIndex(categoryIndex);

    if (id === allCategoriesId) {
      setFilteredByCategoryFlowModels(
        extractFlowModelsFromArray(categorisedFlowModels)
      );
    } else {
      const filteredCategories = runnerCategoryNavigationService.findNodeById(
        categorisedFlowModels,
        id
      );
      setFilteredByCategoryFlowModels(filteredCategories.flowModels);
    }
  };

  const getFlowModelsFromCategorisedFlowModels = () => {
    if (!categorisedFlowModels) {
      return;
    }
    setSearchHidden(!categorisedFlowModels.length);
    let flowModels;
    if (showSubCategories) {
      flowModels =
        runnerCategoryNavigationService.getFlowModelsFromNestedCategories(
          categorisedFlowModels
        );
    } else {
      flowModels = lodashService.flatMap(categorisedFlowModels, 'flowModels');
    }
    setFlowModels(flowModels);
    filterFlowModelsByCategory(selectedCategory);
  };

  const extractFlowModelsFromArray = (categories) => {
    let result = [];
    categories.forEach((category) => {
      if (category.flowModels?.length > 0) {
        result = result.concat(category.flowModels);
      }
      if (category.items?.length > 0) {
        result = result.concat(extractFlowModelsFromArray(category.items));
      }
    });

    return result;
  };

  const updateFlowModelName = (jsonParams: string) => {
    if (!Array.isArray(categorisedFlowModels)) {
      return;
    }

    const params = JSON.parse(jsonParams);
    const updatedMap = updateFlowModelNameInCategorisedData(
      categorisedFlowModels,
      params.id,
      params.name
    );
    if (updatedMap) {
      setCategorisedFlowModels([...categorisedFlowModels]);
    }
  };

  const refresh = () => {
    fetchCategorizedFlowModels(selectedTab, selectedCategory, searchItem);
  };

  const updateFlowModelNameInCategorisedData = (
    categorisedFlowModels,
    flowModelId: Guid,
    nameToBeUpdated: string
  ) => {
    for (const category of categorisedFlowModels) {
      const map = category.flowModels.find((x) => x.id === flowModelId);
      if (map) {
        map.name = nameToBeUpdated;
        return map;
      }

      if (category.items) {
        const foundFlowModel = updateFlowModelNameInCategorisedData(
          category.items,
          flowModelId,
          nameToBeUpdated
        );
        if (foundFlowModel) {
          return foundFlowModel;
        }
      }
    }
    return undefined;
  };

  const handleSearchChange = (value: string) => setSearchItem(value);

  const executeMyApprovalsFilter = () => {
    const filteredValueOnSearch = filteredByCategoryFlowModels.filter(
      (x) =>
        x.description?.toLowerCase().includes(searchItem) ||
        x.name?.toLowerCase().includes(searchItem) ||
        x.author?.toLowerCase().includes(searchItem)
    );
    const ordered = lodashService.orderBy(
      filteredValueOnSearch,
      ['dueBy'],
      ['asc']
    );
    setSearchedFlowModels(ordered);
  };

  const executeProcessReviewDueFilter = () => {
    const filteredValueOnSearch = filteredByCategoryFlowModels.filter(
      (x) =>
        x.description?.toLowerCase().includes(searchItem) ||
        x.name?.toLowerCase().includes(searchItem)
    );
    const ordered = lodashService.orderBy(
      filteredValueOnSearch,
      ['processOverdueOn'],
      ['asc']
    );
    setSearchedFlowModels(ordered);
  };

  const tabActions = {
    [GovernanceTabs.PROCESS_REVIEW_DUE]: {
      fetch: async () => {
        setSearchPlaceholder('Search by flow model name or description');
        await fetchProcessReviewFlowModels();
      },
      filter: () => executeProcessReviewDueFilter(),
      render: (listProps) => <ProcessReviewDueList {...listProps} />
    },
    [GovernanceTabs.MY_APPROVALS]: {
      fetch: async () => {
        setSearchPlaceholder(
          'Search by flow model name, description or author'
        );
        await fetchMyApprovalsFlowModels();
      },
      filter: () => executeMyApprovalsFilter(),
      render: (listProps) => <MyApprovalsList {...listProps} />
    },
    [GovernanceTabs.SENT_FOR_APPROVAL]: {
      fetch: async () => {
        await fetchSentForApprovalFlowModels();
      },
      filter: () => {
        return [];
      },
      render: (listProps) => <SentForApprovalList {...listProps} />
    }
  };

  const fetchCategorizedFlowModels = async (
    tab: GovernanceTabs,
    category = null,
    searchText = null
  ) => {
    if (category == null) {
      setSelectedCategory(allCategoriesId);
      setSelectedCategoryIndex('0');
    }
    if (searchText == null) {
      setSearchItem('');
    }

    await tabActions[tab].fetch();
  };

  const filterAction = () => {
    if (tabActions[selectedTab]?.filter) {
      tabActions[selectedTab].filter();
    }
  };

  const renderSelectedList = () => {
    const listProps = {
      isMobile,
      filteredFlowModels: searchedFlowModels,
      selectedTab,
      appConfig
    };

    return tabActions[selectedTab]?.render
      ? tabActions[selectedTab].render(listProps)
      : null;
  };

  return (
    <div className="categories-row row">
      <div>
        {categorisedFlowModels.length > 0 && (
          <Category
            categorisedItems={categorisedFlowModels}
            selectedCategory={selectedCategory}
            selectedIndex={selectedCategoryIndex}
            allCategoriesId={allCategoriesId}
            isMobile={isMobile}
            onCategoryClick={filterFlowModelsByCategory}
          />
        )}
        <div className={isMobile ? '' : 'cards-col col mt-20'}>
          <div className={`start-category-list ${isMobile ? 'isMobile' : ''}`}>
            <div className="governance-search-tabs-container">
              <span className={`${searchHidden ? 'hidden' : ''}`}>
                <Search
                  placeholder={searchPlaceholder}
                  searchItem={searchItem}
                  onSearchChange={handleSearchChange}
                />
              </span>
              <Tabs
                appConfig={appConfig}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
              />
            </div>
            {renderSelectedList()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Governance;
